<template>
    <v-app class="pay">
      <v-layout wrap justify-center>
        <v-flex xs12 text-center align-self-center>
          <vue-element-loading
            :active="appLoading"
            color="#4B7F2D"
            spinner="bar-fade-scale"
          >
          </vue-element-loading>
          <span style="font-size: 18px">
            Please don't go back or refresh the page, while we redirect you
          </span>
        </v-flex>
      </v-layout>
    </v-app>
  </template>
  <script>
  import axios from "axios";
  export default {
    props: ["paymentId", "orderid"],
    data() {
      return {
        appLoading: true,
      };
    },
    created() {
      this.paymentStatus();
    },
    methods: {
      paymentStatus() {
        axios({
          method: "POST",
          url: "/booking/razorpay/checkstatus",
          data: {
            id: this.$route.query.razorpay_payment_id,
            orderid: this.$route.query.razorpay_invoice_id,
          },
        })
          .then((response) => {
            // console.log(response)
            var PaymentData = null;
            PaymentData = response.data.data;
            if (response.data.status) {
  
            if (PaymentData.payment_status === "success") {
              location.href = "https://ecoshopapi.mudumalaitigerreserve.com/Profile/Orders/";
            } else if (PaymentData.payment_status === "failure") {
              location.href = "https://ecoshopapi.mudumalaitigerreserve.com/cart/";
            }
            else if (PaymentData.payment_status !== "failure" || PaymentData.payment_status !== "success") {
              location.href =
                "https://ecoshopapi.mudumalaitigerreserve.com/Wait?pay=" + this.$route.query.razorpay_payment_id + '&order='+  this.$route.query.razorpay_invoice_id;
            }
            }
            else {
              location.href =
                "https://ecoshopapi.mudumalaitigerreserve.com/Wait?pay=" + this.$route.query.razorpay_payment_id + '&order='+  this.$route.query.razorpay_invoice_id;
            }
          })
          .catch(() => {
            console.log("error");
          });
      },
    },
  };
  </script>
  <style>
  .pay {
    min-height: 100vh;
    min-width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    background-color: white;
    overflow-x: hidden;
  }
  </style>